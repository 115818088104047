import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { sizes } from '../styles/sizes'
import NotFoundPage from './404'
import { default as Home } from './index'
import DetailModal from '../components/detail/DetailModal'
import useSeo from '../hooks/useSeo'
import { getAppDetailAPI } from '../api/apps'
import { RouteComponentProps } from 'react-router-dom'

const getSize = () => {
    if (window.innerWidth > sizes.large) return 'large'
    else if (window.innerWidth > sizes.medium) return 'medium'
    else return 'small'
}

interface MatchParams {
    id: string
}

interface DetailPageProps extends RouteComponentProps<MatchParams> {}

function DetailPage({ match }: DetailPageProps) {
    const id = match.params?.id
    const { setSeo } = useSeo()
    const { data: app, isLoading } = useQuery(['app', id], () => getAppDetailAPI(id))
    const [screen, setScreen] = useState<string>(getSize())

    const handler = () => {
        setScreen(getSize())
    }

    useEffect(() => {
        if (app?.SEO) setSeo(app.SEO)
    }, [app, setSeo])

    useEffect(() => {
        window.addEventListener('resize', handler)
        return () => {
            window.removeEventListener('resize', handler)
        }
    }, [])

    return (
        <>
            {!!id ? (
                <>
                    <Home />
                    <DetailModal fullScreen={screen === 'small'} app={app} loading={isLoading} />
                </>
            ) : (
                <NotFoundPage />
            )}
        </>
    )
}

export default DetailPage
