import {useEffect, useState} from 'react';
import {AccordionDetails, AccordionSummary} from '@material-ui/core';
import styled from 'styled-components';
import useURLParam from '../../hooks/useURLParam';
import palette from '../../styles/palette';
import {DropDown} from '../../static/svg';
import {H3, MobileMenuItem as Accordion, SiteList} from '../Header';
import {initialAllAppCategory} from '../../pages';
import useRequest from '../../hooks/useRequest';
import {getAllCategoryApi} from '../../api';
import useCategories from '../../hooks/useCategories';
import {AppDetailDTO} from '../../api/DTO/Apps';

export type CategoryType = {
    id?: string | null;
    name: string;
    apps?: AppDetailDTO[];
    published_at?: string;
    created_by?: string;
    updated_by?: string;
};

interface ICategoriesProps {
    isMenu?: boolean;
    expanded?: string | false;
    closeMenu?: () => void;
    handleAccordChange?: any;
}

const Categories = ({
                        isMenu = false,
                        expanded,
                        closeMenu,
                        handleAccordChange,
                    }: ICategoriesProps) => {
    const {search, handleParam, removeParam} = useURLParam('category');
    const {setCategories: onHookSetCategories} = useCategories();
    const [categories, setCategories] = useState<Array<CategoryType>>();
    const [selected, setSelected] = useState(search);
    const [getAllCategory, , data] = useRequest(getAllCategoryApi);

    // API: 모든 카테고리 호출
    useEffect(() => {
        getAllCategory();
    }, [getAllCategory]);

    // API: 모든 카테고리 호출
    useEffect(() => {
        if (!data) return;
        const _categories = [initialAllAppCategory(), ...data];
        setCategories(_categories);
        onHookSetCategories(_categories);
        // eslint-disable-next-line eqeqeq
        const _cate = _categories?.filter((cate) => cate.id == search);
        setSelected(_cate[0]?.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, onHookSetCategories]);

    const onClickCategory = (category: string) => {
        setSelected(category);
        if (isMenu) closeMenu();
        if (category) handleParam('category', category);
        else removeParam('category');
    };

    return (
        <>
            {isMenu ? (
                <Accordion
                    expanded={expanded === 'categories'}
                    onChange={handleAccordChange('categories')}
                >
                    <AccordionSummary expandIcon={<DropDown/>}>
                        <H3>앱 카테고리</H3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <SiteList>
                            {categories?.map((category) => (
                                <li
                                    key={category.name}
                                    className={selected === category.id ? 'active' : undefined}
                                >
                                    <CategoryMenuBtn
                                        type={'button'}
                                        onClick={() => onClickCategory(category.id)}
                                    >
                                        {category.name}
                                    </CategoryMenuBtn>
                                </li>
                            ))}
                        </SiteList>
                    </AccordionDetails>
                </Accordion>
            ) : (
                <Block>
                    <h2>앱 카테고리</h2>
                    {categories?.map((category) => (
                        <li
                            key={category.name}
                            className={selected === category.id ? 'active' : undefined}
                        >
                            <CategoryMenuBtn
                                type={'button'}
                                onClick={() => onClickCategory(category.id)}
                            >
                                {category.name}
                            </CategoryMenuBtn>
                        </li>
                    ))}
                </Block>
            )}
        </>
    );
};

const CategoryMenuBtn = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;

  &:hover {
    font-weight: bold;
  }
`;

const Block = styled.div`
  list-style: none;
  margin: 20px 0;
  padding-right: 40px;
  max-width: 200px;
  width: 100%;

  h2 {
    font-size: 24px;
    font-weight: bold;
    color: ${palette.black_1};
    margin: 0 0 28px 0;
    line-height: 200%;
  }

  li.active button {
    color: ${palette.black_1};
    font-weight: 700;
  }

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    padding: 0;
    color: ${palette.gray_2};
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5625;
  }
`;

export default Categories;
