import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/types';
import { setCategories as set } from '../store/categories';

import { CategoryType } from '../components/home/Categories';

export default function useCategories() {
    const dispatch = useDispatch();
    const categories = useSelector((state: RootState) => state.categories);

    const setCategories = useCallback((categories: Array<CategoryType>) => {
        dispatch(set(categories));
    }, [dispatch]);

    return { categories, setCategories };
}
