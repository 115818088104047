import { Helmet } from 'react-helmet-async';
import styled from "styled-components";

export type LoadingMessageProps = {
    mh?: string;
};

function LoadingMessage({ mh = "100vh" }: LoadingMessageProps) {
    return (
        <>
            <Helmet>
                <title>STEP PAY - Please Wait</title>
            </Helmet>
            <Spinner mh={mh}>
                <div className="spinner">
                    <div className="bounce1"/>
                    <div className="bounce2"/>
                    <div className="bounce3"/>
                </div>
            </Spinner>
          {/*  <ErrorScreenTemplate mh={mh} message="PLEASE WAIT A MOMENT 😉" />*/}
        </>
    );
}

const Spinner = styled.div<{ mh :string }>`
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 100%;
  height: ${props => props.mh};
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;

  .spinner {
    margin: 100px auto 0;
    width: 70px;
    text-align: center;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #516FF7;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
  }
`;

export default LoadingMessage;
