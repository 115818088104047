import { Button as MuiButton, ButtonProps, makeStyles } from '@material-ui/core'
import { buttonColorMap, ColorType } from '../../styles/palette'
import { shadow } from '../../styles/theme'

interface IButtonProps extends ButtonProps {
    colormap?: ColorType
    onClick?: any
}

interface IStyleProps {
    colormap?: ColorType
    size?: string
}
const buttonSizeMap: {
    [size: string]: {
        height: string
        maxWidth: string
    }
} = {
    small: {
        height: '38px',
        maxWidth: '120px',
    },
    medium: {
        height: '44px',
        maxWidth: 'auto',
    },
}

const useStyles = makeStyles({
    default: (props: IStyleProps) => ({
        boxShadow: 'none',
        whiteSpace: 'nowrap',
        height: props.size ? buttonSizeMap[props.size]?.height : 'auto',
        maxWidth: props.size ? buttonSizeMap[props.size]?.maxWidth : 'auto',
        padding: '0 20px',
        borderRadius: '10px',
        '&:hover': {
            boxShadow: shadow.button,
        },
        '&.Mui-disabled': {
            color: '#fff',
            backgroundColor: '#D1D1D1',
        },
    }),
    colormap: (props: IStyleProps) =>
        props.colormap
            ? {
                  backgroundColor: buttonColorMap[props.colormap].background,
                  color: buttonColorMap[props.colormap].color,
                  border: ['outline', 'gray'].includes(props.colormap)
                      ? buttonColorMap[props.colormap].border
                      : 'none',
                  '&:hover': {
                      background: buttonColorMap[props.colormap].background,
                      color: buttonColorMap[props.colormap].color,
                  },
              }
            : {},
})

function Button({
    children,
    size = 'small',
    className,
    ref,
    variant = 'contained',
    colormap,
    onClick,
    ...rest
}: IButtonProps) {
    const classes = useStyles({ colormap, size })
    return (
        <MuiButton
            className={`${classes.default} ${classes.colormap} ${className}`}
            ref={ref}
            variant={variant}
            onClick={onClick}
            {...rest}
        >
            {children}
        </MuiButton>
    )
}

export default Button
