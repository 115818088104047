import React, { useEffect } from "react";
import { Popover as MuiPopover, Button, makeStyles } from "@material-ui/core";
import { DropDown } from '../../static/svg';
import styled from "styled-components";

export interface PopoverProps {
    children: React.ReactNode;
    label?: string | React.ReactNode;
    buttonClass?: any;
    className?: string;
}

const useStyles = makeStyles({
    popup: {
        borderRadius: '20px',
        boxShadow: '5px 5px 20px 0px #5D779726'
    }
});

export default function Popover({ label = "open", buttonClass, children }: PopoverProps) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const divRef = React.useRef<HTMLButtonElement>(null);
    const classes = useStyles();
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    function handleClick() {
        setAnchorEl(divRef.current);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    useEffect(() => {
        if (divRef.current && open)
            setAnchorEl(divRef.current);
    }, [divRef, open]);


    return (
        <PopOverWrapper>
            <Button
                ref={divRef}
                aria-describedby={id}
                className={`${buttonClass}`}
                onClick={handleClick}
                endIcon={<DropDown />}
            >
                {label}
            </Button>
            <MuiPopover
                id={id}
                open={open}
                classes={{ paper: `${classes.popup}` }}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                {children}
            </MuiPopover>
        </PopOverWrapper>
    );
}

const PopOverWrapper = styled.div`
  &:hover{
    svg path {
      fill: #516FF7;
    }
  }
`;