import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {Button} from "../common";
import {device} from "../../styles/sizes";
import {useKeycloak} from "@react-keycloak/web";
import {AppDetailDTO, PlanDetail} from "../../api/DTO/Apps";
import {RootState, VendorApp} from "../../store/types";
import {useSelector} from "react-redux";
import useUser from "../../hooks/useUser";
import { useHistory } from 'react-router-dom';

interface StatusButtonProps {
    currentApp: AppDetailDTO;
}

function StatusButton({currentApp}: StatusButtonProps) {
    const history = useHistory()
    const vendor = useSelector((state: RootState) => state.core.vendor)
    const {keycloak} = useKeycloak();
    const [appStatus, setAppStatus] = useState<VendorApp | null>(null)
    const {onLogin} = useUser();
    const [currentPlan, setCurrentPlan] = useState<PlanDetail>();

    const onClickSubscribe = useCallback(() => {
        history.push(`/plans?product_code=${currentApp.product_code}`)
    }, [history, currentApp]);

    const onClickOpenLink = useCallback((url : string) => {
        window.open(url, '_blank');
    }, []);

    useEffect(() => {
        if (vendor && currentApp?.product_code) {
            setAppStatus(vendor.apps.find((_app) => _app.productCode === currentApp.product_code) || null);
        }
    }, [vendor, currentApp]);

    useEffect(() => {
        if (appStatus) {
            if(['ACTIVE', 'PENDING_PAUSE', 'PAUSE'].includes(appStatus.status)){
                setCurrentPlan(() => {
                    return currentApp.prices.find(price => price.price_code === appStatus.priceCode);
                })
            }
        }
    }, [appStatus])


    return (
        <div>
            {keycloak.authenticated
                ? <ButtonGroup>
                    {(!!currentPlan && !!appStatus) ? <SubscribingBtnBox>
                            {currentApp.redirect_url && <Button colormap={'primary'} onClick={() => onClickOpenLink(currentApp.redirect_url)}>열기</Button>}
                            <div className={'planBtnWrapper'}>
                                <span className={'plan'}>{currentPlan.plan_name} 플랜 구독 중</span>
                                <Button colormap={'gray'} disabled={!currentPlan.price_code.includes('FREE')} onClick={onClickSubscribe}>
                                    {!currentPlan.price_code.includes('FREE') ? (appStatus.status === 'PAUSE' ? '일시중지 중' : '구독 중') : '플랜 변경'}
                                </Button>
                            </div>
                        </SubscribingBtnBox>
                        : <Button colormap={'primary'} onClick={onClickSubscribe}>구독하기</Button>}
                </ButtonGroup>
                : (currentApp.method === 'EXTERNAL' ? <Button
                        colormap="primary"
                        onClick={() => onClickOpenLink(currentApp.external_link)}
                    >
                        사용하러가기
                    </Button>
                    : <Button colormap="primary" onClick={onLogin}>로그인하세요</Button>)}
        </div>
    );
}

const SubscribingBtnBox = styled.div`
  display: flex;
  gap: 0.7rem;
  align-items: flex-end;
  
  div.planBtnWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.36rem;


    span.plan {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding:0 0.7rem;
      margin-bottom: 4px;
      font-size: 12px;
      line-height: 18px;
      color: #596268;
      white-space: nowrap;
    }

  }
  
`;

const ButtonGroup = styled.div`
  ${device.tablet} {
    margin-top: 50px;
  }
`;

export default StatusButton;