import React from 'react';
import styled from 'styled-components';
import palette from '../../styles/palette';
import { device } from '../../styles/sizes';
import Button from '../common/Button';

export type ErrorScreenTemplateProps = {
  className?: string;
  message: string;
  buttonText?: string;
  mh?: string;
  onButtonClick?: () => void;
};

function ErrorScreenTemplate({
  className,
  message,
  mh,
  buttonText,
  onButtonClick,
  ...other
}: ErrorScreenTemplateProps) {
  return (
    <Screen mh={mh} className={className} {...other}>
      <div className="message">{message}</div>
      {buttonText && (
        <div className="button-wrapper">
          <Button
            onClick={onButtonClick}
            colormap="primary"
          >
            {buttonText}
          </Button>
        </div>
      )}
    </Screen>
  );
}

const Screen = styled.div <{ mh?: string }>`
  display: flex;
  width: 100%;
  ${({ mh }) => mh ? `max-height: ${mh}` : undefined};
  height: ${({ mh }) => mh ? `${mh}` : '100%'};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .message {
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: pre;
    text-align: center;
    line-height: 1.5;
    font-size: 2.5rem;
    font-weight: 800;
    color: ${palette.black_1};
    ${device.mobile} {
      margin-top: 1rem;
    }
  }
  .button-wrapper {
    margin: 2rem auto;
    ${device.mobile} {
      margin: 1rem auto;
    }
  }
`;

export default ErrorScreenTemplate;
