import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import palette from '../../styles/palette';
import useToggle from '../../hooks/useToggle';
import useInput from '../../hooks/useInput';
import { SearchIcon, CloseIcon } from '../../static/svg';

export interface SearchInputProps {
    className?: string;
    onSearch?: (keyword: string) => void;
    onClear?: () => void;
    placeholder?: string;
    initial?: string;
}

function SearchInput({
    className,
    placeholder = "필요한 앱을 검색해보세요!",
    initial,
    onSearch,
    onClear,
    ...rest
}: SearchInputProps) {
    const [focus, toggleFocus] = useToggle(false);
    const [value, onChange, onReset] = useInput(initial);

    const inputRef = useRef<HTMLInputElement>(null);


    const onClick = () => {
        if (!inputRef.current) return;
        inputRef.current.focus();
    };

    const onKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            onSearch(value);
        }
    };

    const onRemoveClick = () => {
        if (onClear !== undefined) onClear();
        onReset();
    };

    return (
        <SearchInputBlock
            className={className}
            onClick={onClick}
            focus={focus}
            {...rest}
        >
            <SearchIcon />
            <input
                placeholder={placeholder}
                onFocus={toggleFocus}
                onBlur={toggleFocus}
                onKeyPress={onKeyPress}
                ref={inputRef}
                onChange={onChange}
                value={value}
                autoFocus
            />
            <CloseIcon onClick={onRemoveClick} />
        </SearchInputBlock>
    );
}

SearchInput.defaultProps = {
    initial: '',
};

const SearchInputBlock = styled.div<{ focus?: boolean }>`
  display: flex;
  width: 100%;
  height: 3rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  align-items: center;
  transition: all 0.125s ease-in;
  cursor: text;
  background-color: ${palette.gray_3};
  border-radius: 99px;
  svg {
    transition: all 0.125s ease-in;
    margin: 0 0.6rem;
    width: 1.09124rem;
    height: 1.09124rem;
    fill: ${palette.gray_1};
    flex-shrink: 0;
    &:last-child {
        width: 2.095rem;
        height: 2.095rem;
        padding: 0.4312rem;
        cursor: pointer;
    }
  }
  input {
    transition: all 0.125s ease-in;
    font-size: 1rem;
    flex: 1;
    display: block;
    line-height: 1.2rem;
    height: 1.2rem;
    padding: 0;
    border: none;
    outline: 0;
    min-width: 0;
    background-color: inherit;
    &::placeholder {
      color: ${palette.gray_4};
    }
  }

  ${props =>
        props.focus &&
        css`
      input {
        color: ${palette.gray_1};
      }
    `}

`;


export default SearchInput;
