import 'styled-components';
import { palette } from './palette';
import { sizes, device } from './sizes';
// const calcRem = (size: number) => `${size / 16}rem`;


export const shadow = {
    button: '0px 0px 10px 0px rgb(0 0 0 / 10%)'
}

export const theme = {

    colors: palette,
    sizes,
    device,
};

declare module 'styled-components' {
    export interface DefaultTheme {
        colors: any,
        sizes: any,
        device: any
    }
}