import React from 'react';
import { default as Slick } from 'react-slick';
import styled from 'styled-components';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface ISliderProp {
    children: React.ReactNode;
    dots?: boolean;
    arrows?: boolean;
    infinite?: boolean;
    autoplay?: boolean;
    speed?: number;
    autoplaySpeed?: number;
    slidesToShow?: number;
    slidesToScroll?: number;
    ref?: any;
    style?: any;
}

const CustomSlider = React.memo(({
    children,
    style,
    ...option
}: ISliderProp) => {
    var settings = {
        arrows: false,
        dots: false,
        // infinite: true,
        // speed: 500,
        // slidesToShow: 1,
        // slidesToScroll: 1,
        ...option
    };
    return (
        <Slider {...settings}>
            {children}
        </Slider>
    );
});

const Slider = styled(Slick)`
    max-width: 100vw;
    width: 100%;
`;


export default CustomSlider;