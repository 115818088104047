import { useRef } from 'react'
import styled from 'styled-components'
import { device, sizes } from '../../styles/sizes'
import AppCard from './AppCard'
import { AppDetailDTO } from '../../api/DTO/Apps'

export type AppCardListProps = {
    apps: AppDetailDTO[]
    categoryName?: string
    columns?: number
}

function AppCardList({ apps, columns = 3 }: AppCardListProps) {
    const ref = useRef<HTMLDivElement>()

    return (
        <Block ref={ref}>
            {apps.map((app) => (
                <AppCard key={app.id} app={app} />
            ))}
        </Block>
    )
}

const Block = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    row-gap: 27px;

    @media screen and (max-width: ${sizes.large}px) {
        grid-template-columns: repeat(2, 1fr);
    }

    ${device.tablet} {
        grid-template-columns: repeat(1, 1fr);
    }
`

export default AppCardList
