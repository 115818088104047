import {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';

import styled from 'styled-components';
import {Accordion, AccordionDetails, AccordionSummary, makeStyles} from '@material-ui/core';

import {CloseIcon, DropDown, FullLogo, MenuIcon} from '../static/svg';
import Categories from './home/Categories';
import {Popover, SearchBar as SearchInput} from './common';
import palette, {buttonColorMap} from '../styles/palette';
import {device, sizes} from '../styles/sizes';
import Login from './Login';

interface Menu {
    name: string,
    link: string,
    dropdown?: Menu[],
    internal?: boolean,
    target?: '_blank' | '_self'
}

const relativeSiteList: Menu[] = [
    {name: '스텝페이 포탈', link: 'https://portal.steppay.kr/'},
    {name: '홈페이지', link: 'https://steppay.kr/'},
    {name: '개발자 센터', link: 'https://docs.steppay.kr/docs'},
    {name: '헬프 센터', link: 'https://steppay.notion.site/Share-2992bd1142c54a4fa4fb0f5bfb126a7c'}
];

const defaultMenuList: Menu[] = [
    {name: '앱스토어란?', link: 'https://steppay.notion.site/3-93a04110151d4cb3a8e135a60c14c8c5'},
    {name: '스텝페이 포탈', dropdown: relativeSiteList, link: '/'},
    {name: '요청하기', link: 'https://form.typeform.com/to/jmYRxsR0'},
];

const mainMenuList: Menu[] = [
    {name: '전체보기', link: '/', internal: true},
    ...defaultMenuList
];

const Header = () => {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [expanded, setExpanded] = useState<string | false>(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < sizes.medium);


    const mobileMenuList = [
        ...defaultMenuList
    ];

    useEffect(() => {
        const handler = () => {
            setIsMobile(window.innerWidth < sizes.medium);
        };
        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    const handleAccordChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    const onMobileMenuClick = () => {
        setOpen(!open);
    };

    const closeMenu = () => {
        setOpen(false);
    }

    const handleMenuClick = (menu: Menu) => {
        if (menu.internal) {
            history.push(menu.link);
        } else {
            window.open(menu.link, menu.target || '_blank')
        }
    }

    return (
        <Block>
            {
                isMobile ?
                    <Accordion className="menu-accord" expanded={open} onChange={() => onMobileMenuClick()}>
                        <AccordionSummary className="menu-accord-summary"
                                          expandIcon={open ? <CloseIcon/> : <MenuIcon/>}>
                            <LogoWrapper>
                                <Link to={'/'}>
                                    <FullLogo/>
                                </Link>
                            </LogoWrapper>
                        </AccordionSummary>
                        <AccordionDetails className="menu-accord-details">
                            <SearchInput/>
                            <TabWrapper>
                                <Categories isMenu={true} expanded={expanded} closeMenu={closeMenu}
                                            handleAccordChange={handleAccordChange}/>
                                {
                                    mobileMenuList.map((menu) => (
                                        !!menu.dropdown ?
                                            <MobileMenuItem expanded={expanded === menu.name} key={`m-${menu.name}`}
                                                            onChange={handleAccordChange(menu.name)}>
                                                <AccordionSummary expandIcon={<DropDown/>}>
                                                    <H3 className={'mobile'}>{menu.name}</H3>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <SiteList>
                                                        {
                                                            menu.dropdown.map((site) => (
                                                                <li key={`m-${site.name}`}>
                                                                    <OpenLinkBtn type={'button'} onClick={closeMenu}>
                                                                        {site.name}
                                                                    </OpenLinkBtn>
                                                                </li>
                                                            ))
                                                        }
                                                    </SiteList>
                                                </AccordionDetails>
                                            </MobileMenuItem>
                                            :
                                            <MenuItem key={`m-${menu.name}`}>
                                                <OpenLinkBtn className={`${classes.header} mobile`}
                                                             onClick={() => handleMenuClick(menu)}>
                                                    <H3>{menu.name}</H3>
                                                </OpenLinkBtn>
                                            </MenuItem>

                                    ))
                                }
                                <Login className={'mobileLogin'}/>
                            </TabWrapper>
                        </AccordionDetails>
                    </Accordion>
                    :
                    <>
                        <LogoWrapper>
                            <Link to={'/'}>
                                <FullLogo/>
                            </Link>
                        </LogoWrapper>
                        <TabWrapper>
                            {
                                mainMenuList.map((menu) => (
                                    !!menu.dropdown ?
                                        <div key={menu.name} style={{position: 'relative'}}>
                                            <Popover buttonClass={classes.header} label={<H3>{menu.name}</H3>}>
                                                <SiteList>
                                                    {
                                                        menu.dropdown.map((site) => (
                                                            <li key={site.name}>
                                                                <a href={site.link} target="_blank" rel='noreferrer'>
                                                                    {site.name}
                                                                </a>
                                                            </li>
                                                        ))
                                                    }
                                                </SiteList>
                                            </Popover>
                                        </div> :
                                        <OpenLinkBtn key={menu.name} type={'button'} className={classes.header}
                                                     onClick={() => handleMenuClick(menu)}>
                                            <H3>{menu.name}</H3>
                                        </OpenLinkBtn>

                                ))
                            }
                            <Login/>
                        </TabWrapper>
                    </>
            }
        </Block>
    )
}

const OpenLinkBtn = styled.button`
  border: none;
  cursor: pointer;
  background-color: transparent;

  h3 {
    transition: 0.2s;
  }

  &:hover {
    h3 {
      color: #516FF7;
    }
  }
  
  &.mobile:hover{
    h3 {
      color: #000;
      font-weight: bold;
    }
  }
`;

const Block = styled.div`
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 25px 30px;
  white-space: nowrap;
  height: 88px;

  ${device.tablet} {
    max-width: ${sizes.large}px;
    height: 100%;
    flex-direction: column;
    padding: 0;
  }

  .menu-accord {
    position: fixed;
    z-index: 5;

    .menu-accord-summary {
      min-height: 88px;
    }

    .menu-accord-details {
      height: calc(100vh - 88px);
    }

    &.Mui-expanded {
      overflow-y: auto;
      min-height: 88px;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }
  }

  .MuiAccordionDetails-root {
    flex-direction: column;

    .MuiAccordionSummary-root.Mui-expanded {
      min-height: 48px;
      margin-bottom: 20px;

      &:after {
        left: 0;
        right: 0;
        bottom: -8px;
        width: 100%;
        height: 1px;
        content: "";
        position: absolute;
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  [class^="MuiPaper-"] {
    box-shadow: none;
    width: 100%;

    .MuiAccordion-root {
      &.Mui-expanded {
        margin: 0;
      }

      &:before {
        height: 0;
      }
    }
  }
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 227px;
  margin: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    width: 100%;
    display: inline-block;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  flex-direction: row;

  ${device.tablet} {
    margin: 1.67rem auto 1.25rem;
    width: 100%;
    flex-direction: column;
  }

  button {
    border: none;
    cursor: pointer;

    h3 {
      transition: 0.3s;
      transition-timing-function: ease-in-out;
    }

    &:hover {
      h3 {
        color: #516FF7;
      }
    }
  }
  
  button.mobileLogin{
    width: 100% !important;
  }
`;

const _MenuItem = `
    width: 100%;
    ${device.tablet} {
        padding: 10px 0px;
        border-top: 1px solid ${palette.gray_5};
        font-weight: normal;
        &:last-child {
            border-bottom: 1px solid ${palette.gray_5};
        }
    }
`;

export const H3 = styled.h3`
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
  color: ${buttonColorMap['header'].color};

  ${device.tablet} {
    font-size: 1.25rem;
    font-weight: normal;
    color: ${palette.black_1};
  }
  transition:0.2s;
  &.mobile:hover{
    font-weight: bold;
  }
`;

const MenuItem = styled.div`
  ${_MenuItem}
`;

export const MobileMenuItem = styled(Accordion)`
  ${_MenuItem}
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;

    h3 {
      font-weight: bold;
    }
  }
`;


export const SiteList = styled.ul`
  list-style: none;
  padding: 0 20px;
  margin: 20px 0;

  li {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5625;
    text-align: left;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    &.active button {
      color: ${palette.black_1};
      font-weight: 700;
    }

    button {
      font-size: 1.125rem;
      font-weight: 400;
      width: 100%;
      display: inline-block;
      color: ${palette.gray_1};
      text-align: left;
      transition: 0.2s;

    }

    a {
      width: 100%;
      display: inline-block;
      text-decoration: none;
      color: ${palette.gray_1};
      transition: 0.2s;
    }

    &:hover {
      button, a {
        font-weight: bold;
      }
    }
  }

  ${device.tablet} {
    padding: 0;
    margin: 0;

    li {
      a {
        color: ${palette.black_1};
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
    header: {
        padding: '5px 10px',
        margin: '0px 8px',
        borderRadius: '7px',
        boxShadow: 'none',
        backgroundColor: buttonColorMap['header'].background,
        '&:hover': {
            backgroundColor: buttonColorMap['header'].background,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '5px 18px',
            margin: '0px auto',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            '&:hover': {
                boxShadow: 'none',
            },
        },
    },
    loginBtn: {
        width: '100%',
        marginLeft: '8px',
        borderRadius: '10px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
        }
    }
}));

export default Header;