import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router-dom';
import ErrorScreenTemplate from '../components/error/ErrorScreenTemplate';

export type NotFoundPageProps = {};

function NotFoundPage(props: NotFoundPageProps) {
    const history = useHistory();
    return (
        <>
            <Helmet>
                <title>STEP PAY - 404</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <ErrorScreenTemplate
                message="NOT FOUND 😵"
                buttonText="GO HOME"
                onButtonClick={() => { history.push('/'); }}
                mh="70vh"
            />
        </>
    );
}

export default NotFoundPage;
