import { Helmet } from 'react-helmet-async';
import useSeo from '../hooks/useSeo';
import sanitizeHtml from 'sanitize-html';

function SEO() {
    const { seo } = useSeo();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta dangerouslySetInnerHTML={{ __html: sanitizeHtml(seo.description) }} />
                <title>{seo.title}</title>
                {
                    seo.meta?.map(meta => <meta key={meta.name} property={meta.name} content={meta.content} />)
                }
            </Helmet>
        </>
    );
}

export default SEO;
