import {RecurringType} from "../api/DTO/Apps";
import numberWithCommas from "../modules/numberWithCommas";

export const annualPlanToMonthly = (price : number) => {
    return `${numberWithCommas(`${Math.round(price / 12)}`)}원 / 월`;
}

export const calculateAnnualPrice = (price : number, recurring : RecurringType) => {
    switch(recurring){
        case 'ANNUALLY':
            return price;
        case 'MONTHLY':
            return price * 12;
        case 'WEEKLY':
            return price * 52;
        case 'DAILY':
            return price * 365;
        default:
            return 0;
    }
}