import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Divider } from '@material-ui/core'
import palette from '../../styles/palette'
import numberWithCommas from '../../modules/numberWithCommas'
import recurringToString from '../../modules/recurringToString'
import { AppDetailDTO } from '../../api/DTO/Apps'
import { annualPlanToMonthly, calculateAnnualPrice } from '../../utils/utils'
import { useHistory } from 'react-router-dom'

export const methodButtonMap: {
    [method: string]: string
} = {
    AUTO_INSTALL: '설치하기',
    EXTERNAL: '사용하러가기',
    PAYMENT_REQUIRED: '구독하기',
}

export const methodMap: {
    [method: string]: string
} = {
    AUTO_INSTALL: '자동 설치 사용',
    EXTERNAL: '앱 이동 후 사용',
    PAYMENT_REQUIRED: '결제 후 자동 적용',
}

export const languageMap: {
    [lang: string]: string
} = {
    KOREAN: '한국어',
    ENGLISH: '영어',
}

export type AppCardProps = {
    app: AppDetailDTO
}

function AppCard({ app }: AppCardProps) {
    const history = useHistory()
    const steppay = app.categories?.filter((cate) => cate.name === 'STEPPAY') || []
    const [priceToShow, setPriceToShow] = useState<string>()

    useEffect(() => {
        setPriceToShow(() => {
            const annualPrice = annualPlanToMonthly(
                app.prices.map((app) => calculateAnnualPrice(app.price, app.recurring)).sort()[0],
            )

            return annualPrice || '0'
        })
    }, [])

    const handleClick = () => {
        history.push(`/apps/${app.id}`)
    }

    return (
        <>
            {steppay.length > 0 ? (
                <BackImageBlock onClick={handleClick}>
                    <BackgroundImage src={app.image.url} />
                    <Wrapper>
                        <Icon src={app.icon.url} />
                        <h2> {app.name}</h2>
                        {app.prices.length == 0 || app.prices[0].price === 0 ? (
                            <p>Free</p>
                        ) : (
                            <p>
                                {numberWithCommas(`${app.prices[0].price}`)}원/
                                {recurringToString(app.prices[0].recurring)}
                            </p>
                        )}
                    </Wrapper>
                    <p>
                        {app.summary?.length > 150
                            ? `${app.summary.substring(0, 150)}...`
                            : app.summary}
                    </p>
                </BackImageBlock>
            ) : (
                <Block onClick={handleClick}>
                    <Top>
                        <Icon src={app.icon.url} />
                        <h2> {app.name}</h2>
                        <p>{priceToShow}~</p>
                    </Top>
                    <Divider />
                    <p>{app.summary}</p>
                </Block>
            )}
        </>
    )
}

export const Icon = styled.img`
    width: 80px;
    height: 80px;
    margin: auto;
    box-shadow: 0 4px 10px 0 #0000001a;
    border-radius: 20px;
`

const Top = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

export const CommonBlock = styled.div`
    border: 1px solid ${palette.gray_5};
    cursor: pointer;
    border-radius: 10px;
    &:hover {
        box-shadow: 0 0 10px 0 rgb(0 0 0 / 10%);
    }
    h2 {
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.018em;
        text-align: center;
    }

    p {
        margin: 0;
        font-size: 0.825rem;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.018em;
        color: ${palette.gray_2};
    }
`

const Block = styled(CommonBlock)`
    padding: 30px;
    h2 {
        margin: 12px auto 5px;
    }
    .MuiDivider-root {
        margin: 18px auto;
    }
`

const BackImageBlock = styled(CommonBlock)`
    overflow: hidden;
    padding-bottom: 20px;
    & > p {
        padding: 0 20px;
    }
`

const BackgroundImage = styled.div<{ src: string }>`
    background: center / cover no-repeat url('${({ src }) => src}');
    height: 63.253012%;
    min-height: 210px;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto 0 20px;
    padding: 13px 0;
    img {
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-47.328%);
    }
    h2 {
        margin: 0 10px 0 90px;
    }
`
export default AppCard
