import React from 'react'
import { useQuery } from 'react-query'
import { getOrderDetail } from '../api/order'
import { useSelector } from 'react-redux'
import { RootState } from '../store/types'
import useURLParam from '../hooks/useURLParam'
import styled from 'styled-components'
import { getAppListAPI } from '../api/apps'
import LoadingMessage from '../components/error/LoadingMessage'
import { Button } from '../components/common'
import { StringUtils } from '../utils/stringUtils'
import { useHistory } from 'react-router-dom'

function OrderComplete() {
    const { search } = useURLParam('order_code')
    const vendor = useSelector((state: RootState) => state.core.vendor)
    const { data: order, isLoading } = useQuery(
        ['order-complete', vendor, search],
        () => getOrderDetail(vendor.uuid, search),
        {
            enabled: !!vendor && !!search,
        },
    )
    const { data: apps, isLoading: isAppLoading } = useQuery(
        ['product', order?.productCode],
        () => getAppListAPI(order.productCode),
        {
            enabled: !!order?.productCode,
        },
    )
    const history = useHistory()

    return isLoading || isAppLoading || !apps ? (
        <LoadingMessage />
    ) : (
        <Wrapper>
            <PageTitle>
                <h1>Thank you</h1>
                <p>주문이 정상적으로 처리되었습니다.</p>
            </PageTitle>
            <InfoTable>
                <div className={'itemInfo'}>
                    <div className={'product'}>
                        <img
                            src={apps[0]?.icon?.url || apps[0]?.image?.url || ''}
                            alt={'주문 상품 이미지'}
                        />
                        <span>{order?.product}</span>
                    </div>
                    <div className={'plan'}>{order?.amount}원</div>
                </div>
                <InnerRow>
                    <RowTitle>총 주문 금액</RowTitle>
                    <RowValue>{order?.amount}원</RowValue>
                </InnerRow>
                <InnerRow>
                    <RowTitle>총 할인 금액</RowTitle>
                    <RowValue>-0원</RowValue>
                </InnerRow>
                <InnerRow>
                    <RowTitle>총 결제 금액</RowTitle>
                    <RowValue className={'primary'}>{order?.amount}원</RowValue>
                </InnerRow>
            </InfoTable>
            {!!order?.payment && (
                <InfoTable>
                    <h6>결제 정보</h6>
                    <InnerRow>
                        <RowTitle>결제 수단</RowTitle>
                        <RowValue>
                            {order.payment.paymentMethod
                                ? StringUtils.PaymentMethodTranslator(order.payment.paymentMethod)
                                : '-'}
                        </RowValue>
                    </InnerRow>
                    <InnerRow>
                        <RowTitle>카드 번호</RowTitle>
                        <RowValue>{order.payment.cardNumber}</RowValue>
                    </InnerRow>
                </InfoTable>
            )}
            <ButtonGroup>
                <Button
                    colormap={'primary'}
                    size={'medium'}
                    onClick={() => {
                        history.push('/')
                    }}
                >
                    앱 페이지로 돌아가기
                </Button>
                <Button
                    colormap={'outline'}
                    size={'medium'}
                    onClick={() => {
                        window.location.href = 'https://portal.steppay.kr/'
                    }}
                >
                    바로 이용하러 가기
                </Button>
            </ButtonGroup>
        </Wrapper>
    )
}

const PageTitle = styled.div`
    text-align: center;
    margin-bottom: 65px;

    h1 {
        margin: 0;
        line-height: 60px;
        font-size: 50px;
    }

    p {
        margin: 20px 0 0 0;
        font-size: 22px;
    }

    @media screen and (max-width: 780px) {
        margin-bottom: 50px;
        h1 {
            line-height: 36px;
            font-size: 28px;
        }
        p {
            margin: 11px 0 0 0;
            font-size: 16px;
        }
    }
`

const InnerRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border-bottom: 1px solid #e9eaea;

    &:last-of-type {
        border: none;
    }
`

const RowTitle = styled.span`
    color: #828282;
    font-size: 16px;
`

const RowValue = styled.span`
    font-size: 16px;

    &.primary {
        font-weight: bold;
        color: #516ff7;
    }
`

const InfoTable = styled.div`
    width: 100%;
    padding: 0 25px 15px;
    margin-bottom: 25px;
    border: 1px solid #e9eaea;
    border-radius: 10px;

    div.itemInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 130px;
        padding: 22px 0;
        border-bottom: 1px solid #d1d1d1;

        div.product {
            display: flex;
            gap: 15px;

            img {
                width: 80px;
                height: 80px;
                object-fit: cover;
            }

            span {
                display: block;
                padding-top: 20px;
                font-size: 18px;
                font-weight: bold;
                word-break: break-all;
            }
        }
    }

    h6 {
        margin: 25px 0;
        font-size: 18px;
    }
`

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 25px;
`

const Wrapper = styled.div`
    width: 780px;
    max-width: 100%;
    margin: 0 auto;
    padding: 116px 0 300px;

    * {
        transition: 0.3s;
    }

    img {
        width: 200px;
    }

    @media screen and (max-width: 780px) {
        padding: 130px 1rem;
    }
`

export default OrderComplete
