import {useEffect} from 'react';
import styled from 'styled-components';
import {device} from '../../styles/sizes';
import {Button, Slider} from '../common';

import {ImageType} from '../common/Image';
import useRequest from '../../hooks/useRequest';
import {getBannerApi} from '../../api';
import ContentLoader from 'react-content-loader';

export type BannerType = {
    title: string;
    description: string;
    buttonText: string;
    link: string;
    background: ImageType | { url: string };
    published_at: string;
    created_by: string;
    updated_by: string;
};


const Banner = () => {
    const [getBanner, loading, banners] = useRequest(getBannerApi);
    useEffect(() => {
        getBanner();
    }, [getBanner]);


    return (
        <>
            <Slider>
                {
                    loading ?
                        <Wrapper>
                            <ContentLoader/>
                        </Wrapper> :
                        banners?.map(item => (
                            <Wrapper image={item.background?.url} key={item.title}>
                                <h2 dangerouslySetInnerHTML={{__html : item.title || ''}} />
                                <p dangerouslySetInnerHTML={{__html : item.description || ''}} />
                                <Button
                                    size="small"
                                    colormap='primary'
                                    onClick={() => {
                                        window.open(item.link)
                                    }}
                                >
                                    {item.buttonText}
                                </Button>
                            </Wrapper>
                        ))
                }
            </Slider>
        </>
    );
}

const Wrapper = styled.div<{ image?: string }>`
  overflow: hidden;
  ${({image}) => `background: left / cover no-repeat url("${image}")`};
  height: 100%;
  min-height: 394px;
  width: 100%;
  max-width: 100%;
  white-space: break-spaces;
  border-radius: 20px;
  padding: 75px 80px;

  h2 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1.43rem;
  }

  p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 180%;
    margin-bottom: 1.43rem;
  }

  ${device.tablet} {
    padding: 95.5px 30px;

    h2 {
      font-size: 1.625rem;
      line-height: 2.275rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.6875rem;
    }
  }

  ${device.mobile} {
    white-space: inherit;
  }
`;

export default Banner;
