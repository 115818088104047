import { useDispatch } from "react-redux";
import api from "../api/api";
import { AppstoreOrderResponseDTO, CreateOrderDTO } from "../components/common/Order";
import { addVendorApp } from "../store/core";

export default function useOrder() {
    const dispatch = useDispatch();
    
    const createOrder = async (vendorUuid: string, newOrder: CreateOrderDTO, successUrl ?: string) => {
        const order = (await api<AppstoreOrderResponseDTO>('post', `/api/manager/appstore/vendors/${vendorUuid}/orders`, newOrder)).data;
        const redirectUrl = successUrl || `${window.top.location.origin}/order-complete`;

        if(window.self === window.top){//iframe이 아닐 때//페이지 내부일 때
            window.location.href = `${process.env.REACT_APP_PRODUCT_SERVICE_URL}/api/public/orders/${order.code}/pay?successUrl=${encodeURIComponent(redirectUrl)}`;
        }else{//iframe 일 때
            window.top.location.href = `${process.env.REACT_APP_PRODUCT_SERVICE_URL}/api/public/orders/${order.code}/pay?successUrl=${encodeURIComponent(window.top.location.href)}`;
        }
    }

    const addApp = async (vendorUuid: string, orderCode: string) => {
        const productCode = (await api<string>('post', `/api/manager/appstore/vendors/${vendorUuid}/orders/${orderCode}`)).data
      //  dispatch(addVendorApp({ productCode, status: "ACTIVE" }))
    }

    const addAutoInstallApp = async (vendorUuid: string, productCode: string, priceCode = 'FREE', redirectUrl ?: string) => {
        try {
            await api<string>('post', `/api/manager/appstore/vendors/${vendorUuid}/products/${productCode}`)
            dispatch(addVendorApp({ productCode, priceCode, status: "ACTIVE" }));
            if(!!redirectUrl){
                window.top.location = redirectUrl;
            }
        } catch (err) {
            console.error("무료 앱이 아닙니다.")
        }
    }

    return { createOrder, addApp, addAutoInstallApp };
}