import styled from 'styled-components'

const Footer = () => {
    return (
        <FooterWrapper>
            <div className={'footerTop'}>
                <div className={'footerTopWrapper'}>
                    <InfoBox>
                        <FooterLabel>(주) 스텝페이</FooterLabel>
                        <ul>
                            <li>사업자등록번호: 508-87-01464</li>
                            <li>통신판매번호: 2021-서울구로-0459</li>
                            <li>대표자: 김단아</li>
                            <li>본사: 서울시 영등포구 은행로 11, 일신빌딩 2층</li>
                            <li>지사: 서울시 영등포구 의사당대로 83, 오투타워 5층</li>
                            <li>전화: 1666-2837</li>
                        </ul>
                    </InfoBox>
                    <InfoBox>
                        <FooterLabel>제품소개</FooterLabel>
                        <ul>
                            <li>
                                <a href={'https://steppay.kr/'}>스텝페이</a>
                            </li>
                            <li>
                                <a href={'https://stepbuilder.io/'}>스텝빌더</a>
                            </li>
                            <li>
                                <a href={'https://sitekit.steppay.kr/'}>사이트킷</a>
                            </li>
                        </ul>
                    </InfoBox>
                    <InfoBox>
                        <FooterLabel>회사소개</FooterLabel>
                        <ul>
                            <li>
                                <a
                                    href={
                                        'https://steppay.notion.site/Share-_-df1b14b4a5ef4dbeaa43ccd89473cb33'
                                    }
                                >
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a
                                    href={
                                        'https://steppay.notion.site/ada7b67d21d74e7097c2caf2954c98c0'
                                    }
                                >
                                    언론속의 스텝페이
                                </a>
                            </li>
                            <li>
                                <a
                                    href={
                                        'https://steppay.notion.site/b3976b8bd0574ec3a04296f78a751817'
                                    }
                                >
                                    채용안내
                                </a>
                            </li>
                        </ul>
                    </InfoBox>
                    <InfoBox className={'csCenter'}>
                        <FooterLabel>고객센터</FooterLabel>
                        <span className={'email'}>
                            메일문의: <a href={'mailto:contact@steppay.kr'}>contact@steppay.kr</a>
                        </span>
                        <br />
                        <p className={'bottomText'}>
                            <b>고객센터 운영 시간: 월-금 10:00 ~ 18:00</b>
                            <br />
                            *12:00 - 15:00 업무 집중 시간으로 원할한 상담이 어려울 수 있습니다.
                            오른쪽 하단 채팅 버튼을 통해 문의 남겨주시면 순차적으로 빠르게
                            답변드리겠습니다.
                        </p>
                    </InfoBox>
                </div>
            </div>
            <div className={'footerBottom'}>
                <p className={'copyRight'}>
                    Copyright © All reserved <b>STEPPAY</b>
                </p>
                <ul className={'terms'}>
                    <li>
                        <a href={'https://steppay.kr/service'}>이용약관</a>
                    </li>
                    <li>|</li>
                    <li>
                        <a href={'https://steppay.kr/privacy-policy/'}>개인정보취급방침</a>
                    </li>
                </ul>
            </div>
        </FooterWrapper>
    )
}

const FooterLabel = styled.h6`
    font-weight: bold;
    margin: 0 0 1rem 0;
`

const InfoBox = styled.div`
    display: flex;
    flex-direction: column;

    span.email > a {
        text-decoration: underline;
    }

    li {
        padding: 0.25rem 0;
    }

    &.csCenter {
        .bottomText {
            max-width: 17rem;
            white-space: normal;
            word-break: break-word;
            line-height: 170%;
        }
    }

    @media screen and (max-width: 767px) {
        width: 100%;
        padding-left: 1.43rem;
        margin-bottom: 1rem;
        h6 {
            margin-bottom: 0.5rem;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
`

const FooterWrapper = styled.footer`
    width: 100%;
    overflow: hidden;

    li,
    a,
    p,
    span {
        color: #828282;
    }
    b {
        font-weight: bold;
        color: #000;
    }

    div.footerTop {
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;

        div.footerTopWrapper {
            display: flex;
            justify-content: space-evenly;
            flex-direction: row;
            width: 1280px;
            max-width: 100%;
            margin: 0 auto;
            padding: 2rem 0;
        }
    }

    div.footerBottom {
        display: flex;
        width: 1280px;
        max-width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 1.43rem;

        * {
            font-size: 11px;
        }
        ul.terms {
            display: flex;
            align-items: center;
            li,
            a {
                color: #000;
            }
            li:nth-of-type(2) {
                padding: 0.36rem;
            }
        }
    }

    @media screen and (max-width: 767px) {
        div.footerTop {
            .footerTopWrapper {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    @media screen and (max-width: 414px) {
        div.footerBottom {
            flex-direction: column;
            gap: 0.5rem;
        }
    }
`

export default Footer
