import { Divider } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import palette from '../../styles/palette'
import { device } from '../../styles/sizes'
import { languageMap, methodMap } from '../common/AppCard'
import { AppDetailDTO } from '../../api/DTO/Apps'

interface TabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
    app: AppDetailDTO
    loading: boolean
}

const ExplainContent = ({ children, loading, value, index, app, ...other }: TabPanelProps) => {
    return (
        <div hidden={value !== index} {...other}>
            {value === index && (
                <div>
                    <Info>
                        {loading ? (
                            <></>
                        ) : (
                            <>
                                <InfoBox>
                                    <p>사용방식</p>
                                    <h2>{methodMap[app.method]}</h2>
                                </InfoBox>
                                <Divider orientation="vertical" flexItem={true} light={true} />
                                <InfoBox>
                                    <p>카테고리</p>
                                    {app.categories?.length > 0 ? (
                                        <h2 className="blue-box">{app.categories[0].name}</h2>
                                    ) : (
                                        <h2>{app.provider || '-'}</h2>
                                    )}
                                </InfoBox>
                                <Divider orientation="vertical" flexItem={true} light={true} />
                                <InfoBox>
                                    <p>언어지원</p>
                                    <h2>{languageMap[app.language]}</h2>
                                </InfoBox>
                            </>
                        )}
                    </Info>
                    <Inner>
                        <div
                            className="ck-content"
                            dangerouslySetInnerHTML={{ __html: app.description }}
                        />
                    </Inner>
                </div>
            )}
        </div>
    )
}

const Info = styled.div`
    padding: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: ${palette.gray_3};
    border-radius: 20px;
    letter-spacing: 0.018em;
    color: ${palette.gray_2};
    margin-bottom: 50px;
    min-height: 110px;

    ${device.tablet} {
        border-radius: 10px;
        padding: 20px 0;
    }
`

const Inner = styled.div`
    div.markdown {
        text-align: left;

        h5,
        h4,
        h3,
        h2,
        h1,
        strong {
            font-weight: bold;
        }

        h1 {
            font-size: 1.7rem;
            margin: 0 0 0.7rem;
        }

        img {
            width: 100%;
            margin: 0.7rem 0 5rem;
        }

        p {
            width: 100%;
            font-size: 1.125rem;
            font-weight: 400;
            line-height: 150%;
        }
    }
`

const InfoBox = styled.div`
    max-width: 33.333%;
    width: 100%;
    text-align: center;
    padding: 0 10px;
    white-space: nowrap;

    p {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.375rem;
        margin: 0;
    }

    h2 {
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 2.1825rem;
        padding: 8px 20px;
        margin: 9px 0 0;
    }

    h2.blue-box {
        color: ${palette.white};
        background-color: ${palette.primary_light};
        border-radius: 10px;
        display: inline-block;
    }

    ${device.tablet} {
        padding: 0;

        h2,
        p {
            font-size: 1rem;
        }

        h2 {
            padding: 4px 10px;
        }
    }
`

export default ExplainContent
