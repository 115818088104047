import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import './styles/font.css'
import './styles/reset.css'
import './styles/global.css'
import './styles/ckeditor-content.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import rootReducer from './store/reducer'

// 환견변수 설정
require('dotenv').config()

const store = createStore(rootReducer, window.__REDUX_STATE__, composeWithDevTools())

ReactDOM.render(
    <HelmetProvider>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </HelmetProvider>,
    document.getElementById('root'),
)

reportWebVitals()
