import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, SEOType } from '../store/types';
import { setSeo as set, setSeoTitle as setTitle } from '../store/actions';

export default function useSeo() {
    const dispatch = useDispatch();
    const seo = useSelector((state: RootState) => state.core.seo);

    const setSeo = useCallback((seo: SEOType) => {
        dispatch(set(seo));
    }, [dispatch]);

    const setSeoTitle = useCallback((title: string) => {
        dispatch(setTitle(title));
    }, [dispatch]);

    return { seo, setSeo, setSeoTitle };
}
