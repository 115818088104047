import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import useURLParam from '../hooks/useURLParam'
import { getAppListAPI } from '../api/apps'
import { AppDetailDTO, PlanDetail, RecurringType } from '../api/DTO/Apps'
import { Button } from '../components/common'
import { AxiosError } from 'axios'
import styled from 'styled-components'
import { StringUtils } from '../utils/stringUtils'
import ReactMarkdown from 'react-markdown'
import Check from '../static/svg/check.svg'
import RecommendLabelBg from '../static/svg/RecommendLable.svg'
import { useSelector } from 'react-redux'
import { RootState } from '../store/types'
import useUser from '../hooks/useUser'
import useOrder from '../hooks/useOrder'
import { annualPlanToMonthly } from '../utils/utils'
import palette from '../styles/palette'
import { AxiosErrorData } from '../api/DTO/Common'
import numberWithCommas from '../modules/numberWithCommas'
import { useHistory } from 'react-router-dom'

interface PricePlans {
    recurring: RecurringType
    plans: PlanDetail[]
}

interface CurrentApp {
    appId: string
    prices: PricePlans[]
}

function PricingTable() {
    const { search: productCodeParam } = useURLParam('product_code')
    const { search: redirectUrl } = useURLParam('redirect_url')
    const vendor = useSelector((state: RootState) => state.core.vendor)
    const { data: app } = useQuery<AppDetailDTO[], AxiosError, CurrentApp | null>(
        ['product', productCodeParam, vendor?.apps],
        () => getAppListAPI(productCodeParam),
        {
            enabled: !!productCodeParam,
            select: (data) => {
                if (data.length > 0) {
                    const { prices, id } = data[0]
                    const plansByRecurring: PricePlans[] = [
                        {
                            recurring: 'FREE',
                            plans: prices.filter((plan) => plan.recurring.includes('FREE')),
                        },
                        {
                            recurring: 'ANNUALLY',
                            plans: prices.filter((plan) => plan.recurring === 'ANNUALLY'),
                        },
                        {
                            recurring: 'MONTHLY',
                            plans: prices.filter((plan) => plan.recurring === 'MONTHLY'),
                        },
                        {
                            recurring: 'WEEKLY',
                            plans: prices.filter((plan) => plan.recurring === 'WEEKLY'),
                        },
                        {
                            recurring: 'DAILY',
                            plans: prices.filter((plan) => plan.recurring === 'DAILY'),
                        },
                    ]
                    return {
                        appId: id,
                        prices: plansByRecurring,
                    }
                } else {
                    return null
                }
            },
        },
    )
    const { authenticated, onLogin } = useUser()
    const [priceList, setPriceList] = useState<PricePlans[]>([])
    const [freePlan, setFreePlan] = useState<PlanDetail | null>()
    const [recurring, setRecurring] = useState<string>()
    const [ableToPurchase, setAbleToPurchase] = useState<boolean>(true) //TODO 보고 구매 버튼에만 달던가
    const order = useOrder()
    const history = useHistory()

    useEffect(() => {
        if (!!vendor) {
            if (vendor.apps?.length > 0) {
                const currentApp = vendor.apps?.find((app) => app.productCode === productCodeParam)
                if (currentApp?.priceCode.includes('FREE')) {
                    setFreePlan(null)
                    setAbleToPurchase(true)
                } else if (['EXPIRED', 'CANCELED'].includes(currentApp?.status) || !currentApp) {
                    setAbleToPurchase(true)
                } else {
                    console.log('you already purchased this app')
                    setAbleToPurchase(false)
                }
            } else {
                setAbleToPurchase(true)
            }
        }
    }, [vendor?.apps])

    useEffect(() => {
        if (!!app?.prices) {
            const list = app.prices.filter(
                (plan) => !plan.recurring.includes('FREE') && plan.plans.length > 0,
            )
            setPriceList(list)
            list.length > 0 && setRecurring(list[0].recurring)
            if (
                !vendor?.apps
                    .find((app) => app.productCode === productCodeParam)
                    ?.priceCode.includes('FREE')
            ) {
                setFreePlan(
                    app.prices.filter((plan) => plan.recurring.includes('FREE'))[0].plans[0],
                )
            }
        }
    }, [app?.prices, vendor?.apps, productCodeParam])

    const onClickTab = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            const { value } = e.currentTarget
            setRecurring(value)
        },
        [setRecurring],
    )

    const subscribeApp = useCallback(
        (priceCode: string) => {
            if (authenticated) {
                if (ableToPurchase) {
                    if (!priceCode.includes('FREE')) {
                        order
                            .createOrder(
                                vendor!.uuid,
                                {
                                    productCode: productCodeParam,
                                    priceCode: priceCode,
                                },
                                redirectUrl,
                            )
                            .catch((e: AxiosError<AxiosErrorData>) => {
                                if (e.response?.data?.httpStatus === 500) {
                                    window.alert(
                                        '고객을 찾을 수 없습니다. \n 고객센터로 문의해주세요.',
                                    )
                                }
                                console.dir(e)
                            })
                    } else {
                        order
                            .addAutoInstallApp(vendor!.uuid, productCodeParam)
                            .then(() => {
                                history.push(`/apps/${app?.appId}`)
                            })
                            .catch((e: AxiosError<AxiosErrorData>) => {
                                console.dir(e)
                            })
                    }
                } else {
                    window.alert('이미 앱을 구매한 상태입니다.')
                }
            } else {
                onLogin()
            }
        },
        [authenticated, vendor, ableToPurchase, app?.appId],
    )

    return (
        <Wrapper inPage={window.self === window.top}>
            <h2>플랜을 선택해주세요.</h2>
            <ButtonGroup>
                {priceList?.map((plan) => (
                    <Button
                        colormap={plan.recurring !== recurring ? 'gray' : 'primary'}
                        value={plan.recurring}
                        size={'small'}
                        onClick={onClickTab}
                    >
                        {StringUtils.RecurringInTab(plan.recurring)}
                    </Button>
                ))}
            </ButtonGroup>
            <TabCardList>
                {!!freePlan && (
                    <TabContentCard className={'free'}>
                        <div>
                            <span className={'planTitle'}>FREE</span>
                            <span className={'planPrice'}>{freePlan.plan_name}</span>
                        </div>
                        <span className={'emoji'}>😍</span>
                        <div className={'description'}>
                            <ReactMarkdown>{freePlan.description}</ReactMarkdown>
                        </div>
                        <OrderButtonArea>
                            <Button
                                colormap={'primary'}
                                size={'medium'}
                                onClick={() => subscribeApp('FREE')}
                            >
                                {freePlan.button_text}
                            </Button>
                            <span className={'helpText'}>{freePlan.help_text}</span>
                        </OrderButtonArea>
                    </TabContentCard>
                )}
                {priceList
                    ?.filter((price) => price.recurring === recurring)[0]
                    ?.plans.map((plan, index) => (
                        <TabContentCard key={index}>
                            {plan.recommend && <RecommendLabel />}
                            <div className={'planType'}>
                                <span className={'planTitle'}>{plan.plan_name}</span>
                                {plan.recurring === 'ANNUALLY' && (
                                    <span className={'annualPrice'}>
                                        *{numberWithCommas(`${plan.price}`)}원 /{' '}
                                        {StringUtils.RecurringInPlan(plan.recurring)}
                                    </span>
                                )}
                                <span className={'planPrice'}>
                                    {plan.recurring === 'ANNUALLY'
                                        ? annualPlanToMonthly(plan.price)
                                        : `${numberWithCommas(
                                              `${plan.price}`,
                                          )}원 / ${StringUtils.RecurringInPlan(plan.recurring)}`}
                                </span>
                                {!!plan.price2 && (
                                    <div className={'discountBox'}>
                                        <span className={'price2'}>
                                            {annualPlanToMonthly(plan.price2)}
                                        </span>
                                        <span className={'red'}>
                                            {Math.round(
                                                ((plan.price2 - plan.price) / plan.price2) * 100,
                                            )}
                                            % 할인
                                        </span>
                                    </div>
                                )}
                            </div>
                            <div className={'cardBottom'}>
                                <div>
                                    <ReactMarkdown className="markdown">
                                        {plan.description}
                                    </ReactMarkdown>
                                </div>
                                <OrderButtonArea>
                                    <Button
                                        colormap={'primary'}
                                        size={'medium'}
                                        onClick={() => subscribeApp(plan.price_code)}
                                    >
                                        {plan.button_text}
                                    </Button>
                                </OrderButtonArea>
                            </div>
                        </TabContentCard>
                    ))}
            </TabCardList>
            <CopyRight>
                2022 © All rights reserved{' '}
                <Link rel="noopener noreferrer" target="_blank" href="https://steppay.kr">
                    STEPPAY
                </Link>
            </CopyRight>
        </Wrapper>
    )
}

const CopyRight = styled.p`
    margin-top: 26px;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
`

const Link = styled.a`
    text-decoration: none;
    color: ${palette.black};
`

const RecommendLabel = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 72px;
    height: 72px;
    background-position: right top;
    background-repeat: no-repeat;
    background-image: url('${RecommendLabelBg}');
    background-size: cover;
`

const Wrapper = styled.div<{ inPage: boolean }>`
    padding: ${(props) => (props.inPage ? '150px 0' : 0)};

    h2 {
        margin: 0;
        text-align: center;
        font-size: 28px;
    }
`

const OrderButtonArea = styled.div`
    position: absolute;
    left: 24px;
    bottom: 24px;
    width: calc(100% - 48px);

    button {
        width: 100%;
    }

    span.helpText {
        font-size: 12px;
        line-height: 18px;
        color: #828282;
    }
`

const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    margin: 50px 0 26px;
    text-align: center;
`

const TabCardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;

    @media screen and (max-width: 875px) {
        padding: 0 1rem;
        box-sizing: border-box;
    }
`

const TabContentCard = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 270px;
    height: 454px;
    background: #f6f6fa;
    box-shadow: 5px 5px 20px rgba(93, 119, 151, 0.15);
    border-radius: 5px;
    text-align: center;

    &.free {
        padding-top: 56px;

        div.description {
            p {
                margin: 0;
                font-size: 14px;
                line-height: 20px;
            }
        }

        span.emoji {
            display: block;
            font-size: 58px;
            line-height: 58px;
            margin: 18px 0;
        }
    }

    > div {
        display: flex;
        flex-direction: column;
        gap: 12px;

        &.planType {
            width: 100%;
            padding: 30px 0;
            background-color: #fff;

            span.annualPrice {
                font-size: 12px;
                color: #d1d1d1;
            }
        }

        div.discountBox {
            display: flex;
            justify-content: center;
            gap: 10px;

            * {
                font-size: 12px;
                line-height: 18px;
            }

            span.price2 {
                text-decoration: line-through;
            }

            span.red {
                font-weight: bold;
                color: #fd5657;
            }
        }
    }

    > div:last-child {
        gap: 0;
    }

    span.planTitle {
        font-weight: bold;
        font-size: 18px;
    }

    span.planPrice {
        font-weight: bold;
        font-size: 22px;
    }

    div.cardBottom {
        position: relative;
        height: 100%;
        box-sizing: border-box;

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 26px 0 0;
            margin: 0;
            gap: 10px;

            li {
                list-style: none;
                width: fit-content;
                padding-left: 20px;
                background-image: url('${Check}');
                background-repeat: no-repeat;
                background-position: left center;
                font-size: 14px;

                * {
                    font-size: 14px;
                }

                em {
                    font-style: normal;
                    text-decoration: underline;
                }
            }
        }
    }

    @media screen and (max-width: 875px) {
        width: 100%;
    }
`

export default PricingTable
