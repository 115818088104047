import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import HomePage from '../pages'
import DetailPage from '../pages/app-detail'
import SearchPage from '../pages/search'
import NotFoundPage from '../pages/404'
import DefaultLayout from '../components/base/DefaultLayout'
import OrderComplete from '../pages/order-complete'
import PricingTable from '../pages/pricing-table'
import ReactGA from 'react-ga'
import { useKeycloak } from '@react-keycloak/web'
import search from '../pages/search'
import { useLocation } from 'react-router-dom'
import { Analytics, AnalyticsInstance } from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'

const GTMOne = googleTagManager({ containerId: 'GTM-WPSTC3Z' })

function AppRouter() {
    const { pathname } = useLocation()
    const { keycloak } = useKeycloak()
    const [initialized, setInitialized] = useState(false)
    const [analytics, setAnalytics] = useState<AnalyticsInstance | null>(null)

    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            if (keycloak.authenticated) {
                keycloak
                    .loadUserProfile()
                    .then((keycloakProfile) => {
                        ReactGA.initialize('UA-181709570-3', {
                            gaOptions: {
                                name: keycloakProfile.username,
                                userId: keycloakProfile.id,
                            },
                        })
                    })
                    .catch(() => {})
            } else {
                ReactGA.initialize('UA-181709570-3', {})
            }
            setAnalytics(
                Analytics({
                    app: 'appStore',
                    plugins: [GTMOne],
                }),
            )
        }
    }, [keycloak, ReactGA])

    useEffect(() => {
        ReactGA.pageview(pathname + search)
        analytics?.page()
    }, [pathname, ReactGA, analytics])

    return (
        <DefaultLayout>
            <Switch>
                <Route path="/" component={HomePage} exact />
                <Route path="/apps/:id" component={DetailPage} />
                <Route path="/search/" component={SearchPage} />
                <Route path={'/order-complete'} component={OrderComplete} />
                <Route path={'/plans'} component={PricingTable} />
                <Route component={NotFoundPage} />
            </Switch>
        </DefaultLayout>
    )
}

export default AppRouter
