import {PaymentType} from "../api/DTO/Order";
import {RecurringType} from "../api/DTO/Apps";

export class StringUtils {
    static PaymentMethodTranslator(method : PaymentType){
        switch (method){
            case "BANK":
                return "계좌이체";
            case "CARD_BILL" :
                return "카드결제"
            case "VBANK" :
                return "가상계좌";
            case "CELLPHONE" :
                return "핸드폰 소액결제";
            default :
                return "-";
        }
    }

    static RecurringInTab(recurring : RecurringType){
        switch (recurring){
            case "DAILY":
                return "일간";
            case "WEEKLY" :
                return "주간"
            case "MONTHLY" :
                return "월간";
            case "ANNUALLY" :
                return "연간";
            default :
                return "-";
        }
    }

    static RecurringInPlan(recurring : RecurringType){
        switch (recurring){
            case "DAILY":
                return "일";
            case "WEEKLY" :
                return "주"
            case "MONTHLY" :
                return "월";
            case "ANNUALLY" :
                return "연";
            default :
                return "-";
        }
    }
}

