import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useKeycloak } from '@react-keycloak/web';
import { RootState } from '../store/types';
import storage from '../lib/storage';

export default function useUser() {
    const { keycloak } = useKeycloak();
    const user = useSelector((state: RootState) => state.core.user);
    const authenticated = keycloak.authenticated;

    const onLogin = useCallback(() => {
        keycloak.login({
            redirectUri : window.top.location.href
        });
    }, [keycloak]);

    const onLogout = useCallback(async () => {
        try {
            storage.removeItem('USER_PROFILE');
            storage.removeItem('USER_TOKEN');
            keycloak.logout();
        } catch(e) {
            console.dir(e)
        }
    }, [keycloak]);

    return { user, authenticated, onLogin, onLogout };
}
