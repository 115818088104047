import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DetailState } from './types';

export const initialState: DetailState = {
    id: false,
};

const detail = createSlice({
    name: 'detail',
    initialState,
    reducers: {
        setDetailDialog(state, action: PayloadAction<string>) {
            state.id = action.payload;
        },
        closeDetailDialog(state) {
            state.id = false;
        }
    },
});

export default detail.reducer;
export const { setDetailDialog, closeDetailDialog } = detail.actions;
