import { createReducer } from 'typesafe-actions';
import { RootState, CoreState, SEOType } from './types';
import { CoreAction } from './actions';
import { initialState as detailInitialState } from './detail';
import {
    SET_USER,
    SET_TOKEN,
    SET_VENDORS,
    SET_VENDOR,
    ADD_VENDOR_APP,
    OPEN_POPUP,
    CLOSE_POPUP,
    OPEN_APP_DETAIL,
    CLOSE_APP_DETAIL,
    SET_SEO_TITLE,
    SET_SEO,
    SET_CATEGORIES
} from './actions';
import produce from 'immer';

const seoInitialState: SEOType = {
    title: "STEP PAY",
    description: "STEP PAY"
}

const coreInitialState: CoreState = {
    loading: false,
    user: null,
    token: null,
    vendors: [],
    vendor: null,
    seo: seoInitialState,
    popup: {
        visible: false,
        title: '',
        message: '',
    },
};

const initialState: RootState = {
    core: coreInitialState,
    detail: detailInitialState,
    categories: []
}

const core = createReducer<RootState, CoreAction>(initialState, {
    [SET_USER]: (state, action) =>
        produce(state, draft => {
            draft.core.user = action.payload;
        }),
    [SET_TOKEN]: (state, action) =>
        produce(state, draft => {
            draft.core.token = action.payload;
        }),
    [SET_VENDORS]: (state, action) =>
        produce(state, draft => {
            draft.core.vendors = action.payload;
        }),
    [SET_VENDOR]: (state, action) =>
        produce(state, draft => {
            draft.core.vendor = action.payload;
        }),
    [ADD_VENDOR_APP]: (state, action) =>
        produce(state, draft => {
            draft.core.vendor?.apps.push(action.payload);
        }),
    [OPEN_POPUP]: (state, action) =>
        produce(state, draft => {
            draft.core.popup.title = action.payload.title;
            draft.core.popup.message = action.payload.message;
            draft.core.popup.visible = true;
        }),
    [CLOSE_POPUP]: state =>
        produce(state, draft => {
            draft.core.popup.visible = false;
        }),
    [SET_SEO_TITLE]: (state, action) =>
        produce(state, draft => {
            draft.core.seo.title = action.payload;
        }),
    [SET_SEO]: (state, action) =>
        produce(state, draft => {
            draft.core.seo = action.payload;
        }),
    [OPEN_APP_DETAIL]: (state, action) =>
        produce(state, draft => {
            draft.detail.id = action.payload;
        }),
    [CLOSE_APP_DETAIL]: (state) =>
        produce(state, draft => {
            draft.detail.id = false;
        }),
    [SET_CATEGORIES]: (state, action) =>
        produce(state, draft => {
            draft.categories = action.payload;
        }),
});

export default core;
