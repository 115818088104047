import React from 'react'

interface TabPanelProps {
    children?: React.ReactNode
    manual?: string
    index: any
    value: any
}

const DetailContent = ({ children, value, index, manual, ...other }: TabPanelProps) => {
    return (
        <div hidden={value !== index} {...other}>
            {value === index && (
                <div className="ck-content" dangerouslySetInnerHTML={{ __html: manual || '' }} />
            )}
        </div>
    )
}

export default DetailContent
