import React from 'react';
import styled, { createGlobalStyle } from "styled-components";

const BackgroundStyle = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.colors.white};
    margin: 0;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
  .markdown img {
    width: unset;
  }
`;

export interface MainTemplateProps {
  children: React.ReactNode;
};

function MainTemplate({ children }: MainTemplateProps) {
  return (
    <>
      <BackgroundStyle />
      <Block>{children}</Block>
    </>
  );
}

const Block = styled.div``;

export default MainTemplate;