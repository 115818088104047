import request from "./request";

import qs from "qs";
import { CategoryType } from "../components/home/Categories";
import { BannerType } from "../components/home/Banner";
import {AppDetailDTO} from "./DTO/Apps";

export const getAppsInSearchApi = async (keyword: string, categoryId?: string | undefined) => {
    let _where: any = {
        _or: [
            { name_contains: keyword },
            { summary_contains: keyword },
            { description_contains: keyword }
        ]
    }
    if (categoryId) _where['categories.id'] = categoryId;
    const query = qs.stringify({ _where });
    return await request<AppDetailDTO[]>('get', `/apps?${query}`);
}

export const getBannerApi = async () =>
    await request<Array<BannerType>>('get', '/banners');

export const getAllCategoryApi = async () =>
    await request<Array<CategoryType>>('get', '/categories');

// export const getAppsInCategoryApi = async (category: string) =>
//     await request<CategoryType>('get', `/categories/${category}`);
