const mediaQuery = (maxWidth: number) => `
  @media (max-width: ${maxWidth}px)
`;

export const sizes = {
  large: 1280,
  medium: 960,
  small: 600,
  xsmall: 414
};

export const device = {
  tablet: mediaQuery(sizes.medium),
  mobile: mediaQuery(sizes.small),
};