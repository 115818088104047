import { useState, useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

import Banner from '../components/home/Banner';
import Categories, { CategoryType } from '../components/home/Categories';
import ErrorScreenTemplate from '../components/error/ErrorScreenTemplate';
import { SearchBar, AppCardList } from '../components/common';
import useURLParam from '../hooks/useURLParam';
import { device, sizes } from '../styles/sizes';
import useCategories from '../hooks/useCategories';
import useSeo from '../hooks/useSeo';
import {getAppListAPI, getAppsInCategoryApi} from "../api/apps";
import {AppDetailDTO} from "../api/DTO/Apps";
import {useQuery} from "react-query";

export type HomePageProps = {};

const getSize = () => {
    if (window.innerWidth > sizes.large) return 'large';
    else if (window.innerWidth > sizes.medium) return 'medium';
    else return 'small';
};

const columnMap: {
    [size: string]: number;
} = {
    large: 3,
    medium: 2,
    small: 1
}

export const initialAllAppCategory = (apps?: AppDetailDTO[]): CategoryType => ({
    id: null,
    name: "전체 카테고리",
    apps: apps || []
});

function HomePage(props: HomePageProps) {
    const { setSeoTitle } = useSeo();
    const { categories } = useCategories();
    const [screen, setScreen] = useState<string>(getSize());
    const [category, setCategory] = useState<CategoryType>();
    const { search: categoryId } = useURLParam('category');
    const { data : allApps, isLoading : AppsLoading } = useQuery('apps', () => getAppListAPI(), {});
    const { data : categoryData, isLoading : categoryLoading } = useQuery(['apps-in-category', categoryId], () => getAppsInCategoryApi(categoryId), {
        enabled : !!categoryId
    });
    const [apps, setApps] = useState<AppDetailDTO[]>();

    const handler = () => {
        setScreen(getSize());
    }

    useEffect(() => {
        setSeoTitle("스텝페이 | 앱스토어");
    }, [setSeoTitle]);

    useEffect(() => {
        const _cate = categories?.filter(cate => cate.id == categoryId);
        setCategory(_cate[0]);
    }, [categories, categoryId]);

    useEffect(() => {
        setApps([]);
        if (!!categoryId) {
            setApps(categoryData);
        } else {
            setApps(allApps);
        }
    }, [allApps, categoryData]);

    useEffect(() => {
        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    return (
        <Block>
            <Layout>
                <Banner />
                <Content>
                    {
                        screen === 'small' ?
                            <></> :
                            <Categories />
                    }

                    <Inner>
                        <SearchBar mw={sizes.large - 200} />
                        {
                            categoryLoading || AppsLoading ?
                                <ContentLoader width={'100%'} height={'100%'} /> :
                                apps?.length > 0 && !!category ?
                                    <>
                                        <h2> {category.name} </h2>
                                        <AppCardList apps={apps} columns={columnMap[screen]} />
                                    </>
                                    :
                                    <ErrorScreenTemplate
                                        message="NO APPS YET 🧐"
                                    />
                        }
                    </Inner>
                </Content>
            </Layout>
        </Block >
    );
}

const Inner = styled.div`
    margin: 20px auto;
    width: 100%; min-height: 450px;
    & > h2 {margin: 50px auto;}
    ${device.tablet} {
        & > h2 {margin: 60px auto 30px;}
    }
`;

const Content = styled.div`
    display: flex;
    padding: 50px 0;
    ${device.tablet} {
        padding: 20px 0;
    }
`;

const Layout = styled.div`
    max-width: ${sizes.large}px; width: 100%;
    padding: 40px 20px;
    margin: auto;
    ${device.tablet} {
        padding: 10px 20px;
    }
`;

const Block = styled.div`
    position: relative;
    width: 100%; max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: center;
`;

export default HomePage;
