import React, {useCallback, useState} from 'react'
import {useSelector} from 'react-redux'
import {Dialog as MuiDialog, DialogProps, Tab, Tabs} from '@material-ui/core'
import ContentLoader from 'react-content-loader'
import styled from 'styled-components'
import {useKeycloak} from '@react-keycloak/web'
import palette from '../../styles/palette'
import {device} from '../../styles/sizes'
import {Button} from '../common'
import {CloseIcon} from '../../static/svg'
import DetailContent from './DetailContent'
import ExplainContent from './ExplainContent'
import {RootState} from '../../store/core'
import {AppDetailDTO} from '../../api/DTO/Apps'
import StatusButton from "./StatusButton";
import { useHistory } from 'react-router-dom'

export type DetailProps = {
    app: AppDetailDTO
    loading: boolean
    maxWidth?: DialogProps['maxWidth']
    fullWidth?: boolean
    fullScreen?: boolean
}

function DetailModal({app, loading, fullWidth = true, maxWidth = 'xl', fullScreen}: DetailProps) {
    const history = useHistory()
    const {keycloak} = useKeycloak()
    const [value, setValue] = useState(0)
    const vendor = useSelector((state: RootState) => state.core.vendor)

    const handleClose = useCallback(() => {
        history.replace('/');
    }, [history])

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue)
    }

    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            open={true}
            onClose={handleClose}
        >
            <Button colormap="text" className="close-icon" onClick={handleClose}>
                <CloseIcon fill={palette.black_1}/>
            </Button>
            {loading || !app || (keycloak.authenticated && !vendor) ? (
                <Block>
                    <ContentLoader width="100%" height="100%"/>
                </Block>
            ) : (
                <Block>
                    <Top>
                        <div className={'left'}>
                            <figure className="icon">
                                <img src={app.icon?.url} alt="logo"/>
                            </figure>
                            <div className="info">
                                {app.provider ? <h3> {app.provider} </h3> : null}
                                <h2>{app.name}</h2>
                                <p>
                                    {app.summary?.length > 100
                                        ? `${app.summary.substring(0, 100)}...`
                                        : app.summary}
                                </p>
                                {/* <Button className='share' colormap='primaryPale' onClick={() => history.push('')}>
                                        공유하기 <ShareIcon fill={palette.primary}/>
                                    </Button> */}
                            </div>
                        </div>
                        <StatusButton currentApp={app}/>
                    </Top>
                    <Content>
                        <Tabs value={value} indicatorColor="primary" onChange={handleTabChange}>
                            <Tab label="설명"/>
                            <Tab label="사용방법"/>
                        </Tabs>
                        <ExplainContent loading={!app} app={app} value={value} index={0}/>
                        <DetailContent manual={app?.manual} value={value} index={1}/>
                    </Content>
                </Block>
            )}
        </Dialog>
    )
}

const Dialog = styled(MuiDialog)`
  .MuiDialog-paper {
    width: 100%;
    border-radius: 20px;
    min-height: 80%;

    ${device.tablet} {
      border-radius: 0;
    }
  }

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    z-index: 1;

    ${device.tablet} {
      top: 10px;
      right: 10px;
    }
  }
`

const Top = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
  text-align: left;

  figure.icon {
    width: 148px;
    height: 148px;
    margin-right: 25px;
    border-radius: 1.43rem;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  div.left {
    display: flex;

    div.info {
      white-space: nowrap;
      letter-spacing: 0.018em;

      h3,
      p {
        margin: 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        color: ${palette.gray_2};
      }

      h2 {
        margin: 3px 0;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
        color: ${palette.black_2};
      }

      p {

        font-weight: 400;
        margin-bottom: 18px;
        white-space: normal;
        text-align: justify;
      }
    }
  }

  button.share {
    max-width: 150px;

    svg {
      margin-left: 10px;
      width: 15px;
      height: 15px;
    }
  }

  button:not(.share) {
    max-width: 120px;
    width: 100%;
    height: 37px;
    border-radius: 10px;
  }

  ${device.tablet} {
    flex-direction: column;
    align-items: center;
    text-align: center;

    figure.icon {
      width: 130px;
      height: 130px;
      margin: 0 1.43rem 0 0;
    }

    div.info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      p {
        max-width: 100%;
      }
    }

    button:not(.share) {
      max-width: 100%;

    }
  }

  @media screen and (max-width: 414px){
    figure.icon {
      width: 100px;
      height: 100px;
      margin: 0 1rem 0 0;
    }
  }

`

const Content = styled.div`
  & > .MuiTabs-root {
    border-bottom: 1px solid ${palette.gray_5};
    margin-bottom: 50px;
  }

  button.MuiTab-root {
    min-width: auto;
    margin-right: 12px;
  }

  button.MuiTab-root.Mui-selected {
    min-width: auto;
    color: ${palette.primary};
    margin-right: 12px;
  }

  button.MuiTab-root > [class^='PrivateTabIndicator'] {
    color: ${palette.primary} !important;
  }
`

const Block = styled.div`
  background-color: ${palette.white};
  position: relative;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 123px 96px 98px;

  ${device.tablet} {
    padding: 68px 40px;

    img {
      margin-right: 1rem;
    }
  }

  ${device.mobile} {
    padding: 48px 20px;
  }
`

export default DetailModal
