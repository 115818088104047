import { ThemeProvider } from 'styled-components'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import { theme } from './styles/theme'
import AppRouter from './routes/appRouter'
import { QueryClient, QueryClientProvider } from 'react-query'
import { keycloak } from './modules/keycloak'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'
import storage from './lib/storage'
import { User, VendorApp } from './store/types'
import { setUser, setVendor, setVendors } from './store/actions'
import { useStore } from 'react-redux'
import LoadingMessage from './components/error/LoadingMessage'
import api from './api/api'
import { VendorDTO } from './components/common/Vendor'
import { useEffect } from 'react'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            retryOnMount: false,
            retry: false,
        },
    },
})

function App() {
    const store = useStore()
    const loadUser = async (user: User) => {
        storage.setItem('USER_PROFILE', user)
        store.dispatch(setUser(user))
    }

    const getVendors = async () => {
        const vendors = (await api<VendorDTO[]>('get', '/api/manager/vendors')).data
        store.dispatch(setVendors(vendors))

        return vendors
    }

    const getVendorApps = async (vendorUuid: string) => {
        const vendorApps = (
            await api<VendorApp[]>('get', `/api/manager/appstore/vendors/${vendorUuid}/apps`)
        ).data
        store.dispatch(setVendor({ uuid: vendorUuid, apps: vendorApps }))
    }

    const eventLogger = (event: unknown, error: unknown) => {
        if (!!error) console.log('Keycloak Error - ', error)
    }

    const tokenLogger = async (tokens: AuthClientTokens) => {
        if (!keycloak.authenticated) return

        keycloak.loadUserProfile()?.then((profile) => loadUser(profile))

        storage.setItem('USER_TOKEN', keycloak && keycloak.token ? keycloak.token : '')

        const vendors = await getVendors()
        if (vendors.length > 0) {
            await getVendorApps(vendors[0].uuid)
        } else {
            console.error('Vendor not found')
        }
    }

    return (
        <QueryClientProvider client={queryClient}>
            <ReactKeycloakProvider
                authClient={keycloak}
                onEvent={eventLogger}
                onTokens={tokenLogger}
                LoadingComponent={<LoadingMessage />}
            >
                <ThemeProvider theme={theme}>
                    <AppRouter />
                </ThemeProvider>
            </ReactKeycloakProvider>
        </QueryClientProvider>
    )
}

export default App
