import { useState, useEffect } from 'react';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import { SearchInput, AppCardList } from '../components/common';
import Categories from '../components/home/Categories';
import useURLParam from '../hooks/useURLParam';
import useRequest from '../hooks/useRequest';
import { device, sizes } from '../styles/sizes';
import palette from '../styles/palette';
import { getAppsInSearchApi } from '../api';
import { getScreenSize } from '../modules/screensize';
import useSeo from '../hooks/useSeo';
import {AppDetailDTO} from "../api/DTO/Apps";

function SearchPage() {
    const { setSeoTitle } = useSeo();
    const { search: word, handleParam } = useURLParam('word');
    const { search: categoryId } = useURLParam('category');
    const [screen, setScreen] = useState<string>(getScreenSize());
    const [keyword, setKeyword] = useState<string>(word);
    const [apps, setApps] = useState<AppDetailDTO[]>([]);
    const [getAppsInSearch, searchLoading, searchData] = useRequest<AppDetailDTO[]>(getAppsInSearchApi);

    useEffect(() => {
        setSeoTitle("STEP PAY | Search");
    }, [setSeoTitle]);

    useEffect(() => {
        const handler = () => {
            setScreen(getScreenSize());
        }
        window.addEventListener('resize', handler);
        return () => {
            window.removeEventListener('resize', handler);
        };
    }, []);

    const onSearch = async (_keyword: string) => {
        setKeyword(_keyword);   // 새로운 검색 결과로 업데이트
        setApps([]);            // 이전 앱 데이터 삭제
        handleParam("word", _keyword);
        // if (_keyword === "") return;
        await getAppsInSearch(_keyword, categoryId);
    }

    useEffect(() => {
        // if (keyword === "") return;
        getAppsInSearch(keyword, categoryId);
    }, [categoryId, getAppsInSearch, keyword]);

    useEffect(() => {
        setApps(searchData);
    }, [searchData]);

    return (

        <Block>
            <Layout>
                {
                    screen === 'small' ?
                        <></> :
                        <Categories />
                }
                <Content>
                    <SearchInput onSearch={onSearch} initial={keyword} onClear={() => onSearch('')}/>
                    {
                        searchLoading ?
                            <ContentLoader width={'100%'} height={'100%'} />
                            :
                            <>
                                {
                                    keyword === "" ?
                                        <h2> 검색어를 입력해주세요. </h2> :
                                        <h2> "{keyword}"의 검색 결과입니다. </h2>
                                }
                                {
                                    apps?.length > 0 ?
                                        <AppCardList apps={apps} columns={columnMap[screen]} /> :
                                        <h4 className="empty"> :( 검색 결과가 없습니다. </h4>
                                }
                            </>
                    }
                </Content>
            </Layout>
        </Block >
    );
}

const columnMap: {
    [size: string]: number;
} = {
    large: 3,
    medium: 2,
    small: 1
}

const Layout = styled.div`
    display: flex;
    max-width: ${sizes.large}px; width: 100%;
    padding: 40px 20px 80px;
    margin: auto;
    ${device.tablet} {
        padding: 10px 20px;
    }
`;

const Block = styled.div`
    position: relative;
    width: 100%; max-width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${device.tablet} {
        padding: 90px 0 50px;
    }
`;
const Content = styled.div`
    margin: 20px auto;
    width: 100%; min-height: 450px;
    & > h2 {margin: 50px auto;}
    .empty {
        color: ${palette.gray_4};
        margin: 30px auto 60px 0;
        font-size: 2rem;
    }
    ${device.tablet} {
        & > h2 {margin: 60px auto 30px;}
    }
`;

export default SearchPage;
