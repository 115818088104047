import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import MainTemplate from '../base/MainTemplate';
import Header from "../Header";
import Footer from "../Footer";

import storage from '../../lib/storage';
import {setUser} from '../../store/actions';
import SEO from "../Seo";

interface ContextProviderProps {
    children: React.ReactNode;
}

const ContextProvider = ({children}: ContextProviderProps) => {
    const [isIframe, setIsIframe] = useState<boolean>(false);
    const dispatch = useDispatch();
    // let user;

    useEffect(() => {
        const user = storage.getItem('CURRENT_USER');
        if (!!user) dispatch(setUser(user));
    }, [dispatch]);

    useEffect(() => {
        if (window.self !== window.top) setIsIframe(true);
    }, [window.self]);

    return (
        isIframe ?
            <>
                {children}
            </>
            : <>
                <SEO/>
                <MainTemplate>
                    <Header/>
                    {children}
                    <Footer/>
                </MainTemplate>
            </>
    );
};

export default ContextProvider;