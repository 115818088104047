import axios, { Method, AxiosResponse } from 'axios';
import storage from '../lib/storage';

const _api = axios.create({
    baseURL: process.env.REACT_APP_ACCOUNT_SERVICE_URL,
});

_api.interceptors.request.use(config => {
    const keycloakToken = storage.getItem('USER_TOKEN');

    if(config.url?.startsWith("/api/manager/")) {
        config.headers = {
            authorization : `Bearer ${keycloakToken}`
        }
    }

    return config
})

const api = async <T>(method: Method, url: string, data?: any): Promise<AxiosResponse<T>> => {
    return await _api.request<T>({
        method,
        url,
        data,
    });
};

export default api;