import { ActionType, deprecated } from 'typesafe-actions';
import { VendorDTO } from '../components/common/Vendor';
import { CategoryType } from '../components/home/Categories';

import * as actions from './actions';
import { SEOType, User, VendorApp } from './types';

const { createStandardAction } = deprecated;

export const SET_USER = 'core/SET_USER';
export const SET_TOKEN = 'core/SET_TOKEN';
export const SET_VENDORS = 'core/SET_VENDORS'
export const SET_VENDOR = 'core/SET_VENDOR'
export const ADD_VENDOR_APP = 'core/ADD_VENDOR_APP'

export const OPEN_POPUP = 'core/OPEN_POPUP';
export const CLOSE_POPUP = 'core/CLOSE_POPUP';

export const SET_SEO = 'core/SET_SEO';
export const SET_SEO_TITLE = 'core/SET_SEO_TITLE';

export const OPEN_APP_DETAIL = 'detail/setDetailDialog';
export const CLOSE_APP_DETAIL = 'detail/closeDetailDialog';

export const SET_CATEGORIES = 'categories/setCategories';

export const setUser = createStandardAction(SET_USER)<User | null>();
export const setToken = createStandardAction(SET_TOKEN)<string | null>();
export const setVendors = createStandardAction(SET_VENDORS)<VendorDTO[]>();
export const setVendor = createStandardAction(SET_VENDOR)<{ uuid: string, apps: VendorApp[] } | null>();
export const addVendorApp = createStandardAction(ADD_VENDOR_APP)<VendorApp>();
export const openPopup = createStandardAction(OPEN_POPUP)<{
    title?: string;
    message: string;
}>();
export const setSeo = createStandardAction(SET_SEO)<SEOType>();
export const setSeoTitle = createStandardAction(SET_SEO_TITLE)<string>();
export const closePopup = createStandardAction(CLOSE_POPUP)();
export const openAppDetail = createStandardAction(OPEN_APP_DETAIL)<string | false>();
export const closeAppDetail = createStandardAction(CLOSE_APP_DETAIL)();
export const setCategories = createStandardAction(SET_CATEGORIES)<Array<CategoryType>>();

export type CoreAction = ActionType<typeof actions>;