import { useEffect, useState, useRef } from 'react'
import { Popover as MuiPopover, makeStyles, Divider } from '@material-ui/core'
import styled from 'styled-components'
import ContentLoader from 'react-content-loader'
import palette from '../../styles/palette'
import { ArrowRightIcon } from '../../static/svg'
import { SearchInput, Button } from '.'
import useRequest from '../../hooks/useRequest'
import { getAppsInSearchApi } from '../../api'
import { AppDetailDTO } from '../../api/DTO/Apps'
import { Link, useHistory } from 'react-router-dom'
import { shadow } from '../../styles/theme'

const useStyles = makeStyles({
    popup: {
        width: '100%',
        minHeight: '100px',
        borderRadius: '10px',
        boxShadow: '0px 15px 15px rgba(0, 0, 0, 0.15)',
    },
    moreButton: {
        maxWidth: 'none',
        fontSize: '1.125rem',
        fontWeight: 'normal',
        lineHeight: '26px',
    },
})

interface ISearchBarProps {
    mw?: number
}

const SearchBar = (props: ISearchBarProps) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [apps, setApps] = useState<AppDetailDTO[]>([])
    const [keyword, setKeyword] = useState<string>('')
    const [getAppsInSearch, loading, searchData] = useRequest(getAppsInSearchApi)
    const [inputWidth, setInputWidth] = useState<number>(0)
    const divRef = useRef<HTMLDivElement>(null)
    const open = Boolean(anchorEl)
    const classes = useStyles()
    const history = useHistory()
    const id = open ? 'simple-popover' : undefined

    function handleClose() {
        setAnchorEl(null)
    }

    useEffect(() => {
        if (divRef.current && open) {
            setAnchorEl(divRef.current)
            setInputWidth(divRef.current.offsetWidth)
        }
    }, [divRef, open])

    const onSearch = async (_keyword: string) => {
        setAnchorEl(divRef.current)
        setKeyword(_keyword)

        if (_keyword === '') {
            setApps([])
            return
        }
        await getAppsInSearch(_keyword)
    }

    useEffect(() => {
        if (!searchData) return
        setApps(searchData?.slice(0, 3))
    }, [searchData])

    return (
        <>
            <div ref={divRef}>
                <SearchInput onSearch={onSearch} onClear={() => onSearch('')} />
            </div>
            <MuiPopover
                id={id}
                open={open}
                classes={{ paper: `${classes.popup}` }}
                anchorEl={anchorEl}
                onClose={handleClose}
                style={{ maxWidth: inputWidth + 'px' }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {
                    <AppWrapper>
                        {loading ? (
                            <ContentLoader width={'100%'} height={'100%'} />
                        ) : apps.length > 0 ? (
                            apps.map((app) => (
                                <LinkWrapper key={app.id} to={`/apps/${app.id}`}>
                                    <Image src={app.icon?.url} alt={app.name} />
                                    <Description>
                                        <AppTitle>{app.name}</AppTitle>
                                        <AppSummary>{app.summary}</AppSummary>
                                    </Description>
                                </LinkWrapper>
                            ))
                        ) : (
                            <h4 className="empty"> :( 검색 결과가 없습니다. </h4>
                        )}
                        <Divider />
                        <Button
                            className={classes.moreButton}
                            onClick={() => history.push(`/search?word=${keyword}`)}
                            endIcon={<ArrowRightIcon fill={palette.gray_2} />}
                            colormap="text_2"
                        >
                            모든 검색 결과 보기
                        </Button>
                    </AppWrapper>
                }
            </MuiPopover>
        </>
    )
}

SearchInput.defaultProps = {
    initial: '',
}

const AppWrapper = styled.div<{ mw?: number }>`
    padding: 30px 30px 20px;
    width: 100%;
    max-width: ${({ mw }) => (mw ? mw + 'px' : '100%')};
    hr {
        margin: 15px auto;
    }
    .empty {
        color: ${palette.gray_1};
        margin: 30px auto 60px 20px;
        font-size: 1.256rem;
    }
`

const LinkWrapper = styled(Link)`
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    text-decoration: none;

    &:hover {
        box-shadow: ${shadow.button};
    }
`

const Image = styled.img`
    width: 60.17px;
    height: 60.17px;
    border-radius: 15px;
`

const Description = styled.div`
    margin-left: 20px;
`

const AppTitle = styled.h3`
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.018em;
    color: ${palette.black};
`

const AppSummary = styled.p`
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.018em;
    color: ${palette.gray_2};
`

export default SearchBar
