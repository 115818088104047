import { useHistory, useLocation } from "react-router-dom";

type QueryType =
    | "category"
    | "word"
    | "id"
    | "success"
    | "product_code"
    | "order_code"
    | "redirect_url";

/*

:: useURLParam

* input:
    search: Keyword For Get Query Data
* output:
    query: URLSearchParams Object (utility methods to work with the query string of a URL)
    search: Query Data From keyword

* ex:
    /search?word=app&category=3
    input 'search' : word
    output 'search' : app

    + query.toString() -> search=app&category=3
*/

export default function useURLParam(search?: QueryType): {
    query: URLSearchParams,
    search: string
    appendParam: (name: string, _query: string) => void
    handleParam: (name: string, _query: string) => void
    removeParam: (name: string) => void
    removeParams: (names: string[]) => void
} {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);

    const appendParam = (name: string, _query: string) => {
        query.append(name, _query);
        history.push({ search: query.toString() });
    }

    const handleParam = (name: string, _query: string) => {
        if (query.has(name)) query.delete(name);
        query.append(name, _query);
        history.push({ search: query.toString() });
    }

    const removeParam = (name: string) => {
        query.delete(name);
        history.push({ search: query.toString() });
    }

    const removeParams = (names: string[]) => {
        names.forEach(name => query.delete(name));
    }

    return { query, search: query.get(search), appendParam, handleParam, removeParam, removeParams }
}