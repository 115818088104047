import axios, { Method, AxiosResponse } from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_CMS_URL,
});

const request = async <T>(method: Method, url: string, params?: any): Promise<AxiosResponse<T>> => {
    return await api.request<T>({
        method,
        url,
        params,
    });
};

export default request;