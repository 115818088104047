import request from "./request";
import {AppDetailDTO} from "./DTO/Apps";

export const getAppListAPI = async (productCode ?: string) => {
    let url = '/apps'

    if(!!productCode){
        url = url.concat('?', `product_code=${productCode}`)
    }

    return (await request<AppDetailDTO[]>('get', url)).data;
}

export const getAppDetailAPI = async (app: string) => {
    return (await request<AppDetailDTO>('get', `/apps/${app}`)).data;
}

export const getAppsInCategoryApi = async (category: string) => {
    return (await request<AppDetailDTO[]>('get', `/apps?categories.id=${category}`)).data;
}
