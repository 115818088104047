import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoryType } from '../components/home/Categories';

const categories = createSlice({
    name: 'categories',
    initialState: [],
    reducers: {
        setCategories(state, action: PayloadAction<Array<CategoryType>>) {
            state = action.payload;
        }
    },
});

export default categories.reducer;
export const { setCategories } = categories.actions;
