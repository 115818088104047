export type ColorType =
    | 'primary'
    | 'outline'
    | 'gray'
    | 'header'
    | 'text'
    | 'text_2'
    | 'primaryPale'

export const palette = {
    black: "#000000",
    white: "#FFFFFF",
    primary: "#516FF7",
    primary_light: "#7F9FFF",
    black_1: "#292E30",
    black_2: "#19191D",
    gray_1: "#5A5B6A",
    gray_2: "#596268",
    gray_3: "#F6F6FA",
    gray_4: "#D1D1D1",
    gray_5: "#E9EAEA",
    gray_6: "#828282",
    blue_2: "#E6EAFE",
};

export const buttonColorMap: {
    [color in ColorType]: {
        background: string;
        color: string;
        border?: string;
    };
} = {
    primary: {
        background: palette.primary,
        color: palette.white
    },
    primaryPale: {
        background: palette.blue_2,
        color: palette.primary
    },
    outline: {
        background: palette.white,
        color: palette.primary,
        border: `1px solid ${palette.primary}`
    },
    gray: {
        background: palette.white,
        color: palette.gray_4,
        border: `1px solid ${palette.gray_4}`
    },
    header: {
        background: 'transparent',
        color: palette.gray_1
    },
    text: {
        background: 'transparent',
        color: palette.black
    },
    text_2: {
        background: 'transparent',
        color: palette.gray_2
    }
};

export default palette;
