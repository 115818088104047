import { Button } from './common';
import { makeStyles } from '@material-ui/core';
import useUser from '../hooks/useUser';

interface ButtonProps {
    className ?: string
}

const Login = ({ className } : ButtonProps) => {
    const { authenticated, onLogin, onLogout } = useUser();
    const classes = useStyles();

    return (
        <>
            {authenticated ?
                <Button
                    onClick={onLogout}
                    className={`${classes.loginBtn} ${className || ''}`}
                    size="small"
                    colormap='primary'>
                    로그아웃 </Button>
                :
                <Button
                    onClick={onLogin}
                    className={`${classes.loginBtn} ${className || ''}`}
                    size="small"
                    colormap='primary'>
                    로그인 </Button>
            }
        </>
    )
}

const useStyles = makeStyles(theme => ({
    loginBtn: {
        width: '100%',
        marginLeft: '8px',
        borderRadius: '10px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
        }
    }
}));

export default Login;